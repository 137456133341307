import React, { useState } from "react";
import { motion } from "framer-motion";
import { Portal } from "react-portal";
import Button from "components/button";
import CloseSvg from "../../images/close.inline.svg";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import GradientText from "components/gradient-text";
import RadioButton from "components/radiobutton";
import LoadingIcon from "components/loading-icon";
import "./ReportMessage.scss";

const ReportMessage = React.memo(
    ({
        messagePageData,
        isReportMessage,
        setReportMessage,
        messageId,
        flags,
    }) => {
        const size = useWindowSize();
        const [checked] = useState(null);
        const [checkedValue, setCheckedValue] = useState(null);
        const deviceBreakPoint = size.width < breakpoints.md;
        const [isLoading, setIsLoading] = useState(false);
        const [isConfirmed, setConfirmation] = useState(false);
        const [isSubmit, setSubmit] = useState(false);

        // Animations
        const animatePortal = {
            exit: {
                opacity: 0,
            },

            enter: {
                opacity: 1,
                transition: {
                    type: "tween",
                    ease: "easeIn",
                    duration: 0.5,
                },
            },
        };

        const animateList = {
            initial: {
                opacity: 0,
            },

            enter: {
                opacity: 1,
                transition: {
                    when: "beforeChildren",
                    staggerChildren: 0.1,
                    delay: 0.3,
                },
            },
        };

        const animateListItem = {
            initial: {
                opacity: 0,
                y: 20,
            },

            enter: {
                opacity: 1,
                y: 0,
                transition: {
                    type: "spring",
                    ease: "easeIn",
                },
            },
        };

        const animateBtn = {
            initial: {
                opacity: 0.4,
            },

            enter: {
                opacity: 1,
                transition: {
                    type: "tween",
                    ease: "easeIn",
                },
            },
        };

        const animateConfirmation = {
            initial: {
                opacity: 0,
                y: 20,
            },

            enter: {
                opacity: 1,
                y: 0,
                transition: {
                    type: "spring",
                    ease: "easeIn",
                },
            },
        };

        // Functions
        const closeReportMessage = () => {
            setReportMessage(false);
            setCheckedValue(null);
            setSubmit(false);
            setConfirmation(false);
        };

        const handleChange = (e) => {
            setCheckedValue(e.target.id);
        };

        const sendMessage = () => {
            if (checkedValue) {
                setSubmit(true);
                setIsLoading(true);
                const data = {
                    message_id: messageId,
                    reason_flag: checkedValue,
                    device: "web",
                };
                return fetch(
                    "https://europe-west1-superwe-api.cloudfunctions.net/app/report",
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        method: "POST",
                        body: JSON.stringify(data),
                    }
                )
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.result !== undefined) {
                            setIsLoading(false);
                            setConfirmation(true);
                        }
                    });
            } else {
                return;
            }
        };

        return (
            <>
                {isReportMessage && (
                    <Portal>
                        <motion.div
                            initial="exit"
                            animate={isReportMessage ? "enter" : "exit"}
                            variants={animatePortal}
                            className="report-overlay"
                        >
                            <motion.button
                                initial="initial"
                                animate="enter"
                                variants={animatePortal}
                                onClick={() => closeReportMessage()}
                                className="close-btn"
                            >
                                <CloseSvg />
                            </motion.button>
                            <div className="report-content">
                                <GradientText
                                    gradientText={
                                        messagePageData?.reportMessageTitle
                                    }
                                    heading={false}
                                    fontSize={
                                        deviceBreakPoint ? "1.8rem" : "2.4rem"
                                    }
                                    deviceFontSize={
                                        deviceBreakPoint ? "1.8rem" : "2.4rem"
                                    }
                                />

                                <div>
                                    {isLoading && <LoadingIcon color="grey" />}
                                </div>

                                {!isLoading && !isSubmit && (
                                    <>
                                        <p className="report-text">
                                            {messagePageData?.reportMessageText}
                                        </p>

                                        <motion.ul
                                            className="report-list"
                                            initial="initial"
                                            animate="enter"
                                            variants={animateList}
                                        >
                                            {flags.edges.map((flag, key) => (
                                                <motion.li
                                                    key={key}
                                                    variants={animateListItem}
                                                >
                                                    <RadioButton
                                                        id={flag.node.slug}
                                                        name="radiobtn"
                                                        checked={checked}
                                                        withLabel={true}
                                                        onChange={(e) =>
                                                            handleChange(e)
                                                        }
                                                        htmlFor={flag.node.slug}
                                                        text={flag.node.name}
                                                    />
                                                </motion.li>
                                            ))}
                                        </motion.ul>
                                        <motion.div
                                            initial="initial"
                                            animate={
                                                checkedValue
                                                    ? "enter"
                                                    : "initial"
                                            }
                                            variants={animateBtn}
                                        >
                                            <Button
                                                className="report-btn"
                                                ariaLabel="send report message"
                                                onClick={() => sendMessage()}
                                                pulse={false}
                                                disabled={
                                                    checkedValue ? false : true
                                                }
                                            >
                                                <span>
                                                    {
                                                        messagePageData?.submitButtonText
                                                    }
                                                </span>
                                            </Button>
                                        </motion.div>
                                    </>
                                )}

                                {isConfirmed && isLoading === false && (
                                    <motion.div
                                        initial="initial"
                                        animate="enter"
                                        variants={animateConfirmation}
                                        className="confirmation-content"
                                    >
                                        <p className="confirmation-txt">
                                            {
                                                messagePageData?.reportConfirmationText
                                            }
                                        </p>
                                        <Button
                                            className="report-btn"
                                            ariaLabel="close report overlay"
                                            onClick={() => closeReportMessage()}
                                            pulse={false}
                                        >
                                            <span>
                                                {
                                                    messagePageData?.reportCloseText
                                                }
                                            </span>
                                        </Button>
                                    </motion.div>
                                )}
                            </div>
                        </motion.div>
                    </Portal>
                )}
            </>
        );
    }
);

export default ReportMessage;
