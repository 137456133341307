import React from "react";
import "components/checkbox/Checkbox.scss";

const RadioButton = React.memo(
    ({ id, name, checked, onChange, htmlFor, text, withLabel }) => {
        return (
            <div className="checkbox">
                <div className="checkbox__box">
                    <input
                        type="radio"
                        id={id}
                        name={name}
                        checked={checked}
                        onChange={onChange}
                    />

                    <label htmlFor={htmlFor}>
                        {withLabel && (
                            <span
                                className="checkbox__labelText"
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        )}
                        <div className="checkbox__labelBox" />
                    </label>
                </div>
            </div>
        );
    }
);

export default RadioButton;
