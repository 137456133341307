import React from "react";
import PropTypes from "prop-types";
import "./OverlayShadow.scss";

const OverlayShadow = React.memo(({ ariaLabel, componentClass, position }) => {
    // position: overlay-shadow--top/overlay-shadow--bottom
    // componentClass: specific class you want to add
    return (
        <div
            className={`overlay-shadow overlay-shadow--${position} ${componentClass}`}
        ></div>
    );
});

OverlayShadow.propTypes = {
    position: PropTypes.string.isRequired,
    componentClass: PropTypes.string,
};

export default OverlayShadow;
