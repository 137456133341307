import React from "react";
import PropTypes from "prop-types";
import MessageTemplate from "templates/message/MessageTemplate";
import CookieConsent from "components/cookie-consent";
// import SmartBanner from "react-smartbanner";
// import "react-smartbanner/dist/main.css";
import "./Layout.scss";

const MessageLayout = ({
    pageType,
    messageData,
    messagePageData,
    flagsData,
    globalTexts,
    locale,
    location,
    categories,
}) => {
    return (
        <>
            {/* <SmartBanner /> */}
            <CookieConsent language={locale} />
            <main className={pageType}>
                <MessageTemplate
                    locale={locale}
                    messageData={messageData}
                    messagePageData={messagePageData}
                    categories={categories}
                    flagsData={flagsData}
                    globalTexts={globalTexts}
                    location={location}
                />
            </main>
        </>
    );
};

MessageLayout.propTypes = {
    pageType: PropTypes.string,
};

export default MessageLayout;
