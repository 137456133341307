import React, { useState, useRef, useEffect } from "react";
import withLocation from "hooks/withLocation";
import { navigate } from "gatsby";
import "./MessageTemplate.scss";
import ReportMessage from "components/report-message";
import { motion } from "framer-motion";
import ReportIcon from "images/report-message-icon.inline.svg";
import MapIcon from "images/map-icon.inline.svg";
import SocialIcon from "images/social-share-icon.inline.svg";
import IconButton from "components/icon-button";
import LoadingIcon from "components/loading-icon";
import "rc-slider/assets/index.css";
import CloseButton from "components/close-button";
import ReactPlayer from "react-player";
import ShareButton from "components/share-button";
import { window, exists } from "browser-monads";
import classNames from "classnames";
import OverlayShadow from "components/overlay-shadow/OverlayShadow";
import ProgressBar from "components/progress-bar";
import getCategoryColor from "constants/categoryColors";
import { Helmet } from "react-helmet";

const MessageTemplate = ({
    locale,
    messagePageData,
    flagsData,
    globalTexts,
    search,
    location,
    categories,
}) => {
    const [videoId, setVideoId] = useState(null);
    const [isReportMessage, setReportMessage] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [isLoading, setIsloading] = useState(true);
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [duration, setDuration] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const videoRef = useRef();
    const messageClassName = classNames("message", {
        "message--blur": isReportMessage,
    });

    const [bgColor, setBgColor] = useState("");

    const handleProgress = (state) => {
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            setPlayed(state.played);
        }
    };

    const handleDuration = (duration) => {
        setDuration(duration);
    };

    const togglePlaystate = () => {
        setPlaying(!playing);
    };

    const onSliderChange = (value) => {
        setPlayed(value / 100);
        videoRef.current.seekTo(parseFloat(value / 100));
    };

    const videoProps = {
        playing,
        playsinline: true,
        url: videoId,
        volume: 1,
        loop: true,
        width: "100%",
        height: "100%",
        onProgress: handleProgress,
        onDuration: handleDuration,
    };

    const closeMessage = (goToMap = false) => {
        const latLng = currentMessage?.data.location;
        const state = {
            messageCoordinates: latLng,
        };

        if (location.state && location.state.selectedCategories) {
            state.selectedCategories = location.state.selectedCategories;
            state.selectedCountries = location.state.selectedCountries;
            state.selectedFilters = location.state.selectedFilters;
        }

        if (location.state && location.state.zoom) {
            state.zoom = location.state.zoom;
        } else if (goToMap) {
            state.zoom = 15;
        }

        if (!goToMap) {
            if (
                location.state &&
                location.state.prevPath &&
                location.state.fromFrontpage
            ) {
                navigate(location.state.prevPath);
            } else {
                navigate(`/${locale}/map`, {
                    state,
                });
            }
        } else {
            navigate(`/${locale}/map`, {
                state,
            });
        }
    };

    const onBeforeChange = () => {
        setSeeking(true);
    };

    const onAfterChange = () => {
        setSeeking(false);
    };

    useEffect(() => {
        const querySingleMessage = async (id) => {
            try {
                const response = await fetch(
                    process.env.GATSBY_PROXY === "true"
                        ? `/superwe-api/europe-west1/app/messages/${id}`
                        : `https://europe-west1-superwe-api.cloudfunctions.net/app/messages/${id}`
                );
                const data = await response.json();
                if (typeof data === "string") {
                    return navigate(`/${locale}/not-found-video-message`);
                } else {
                    return data;
                }
            } catch (e) {
                return navigate(`/${locale}/not-found-video-message`);
            }
        };

        const getSingleMessage = (id) => {
            querySingleMessage(id || search.id)
                .then((messageData) => {
                    if (messageData) {
                        if (typeof messageData === "string") {
                            return navigate(
                                `/${locale}/not-found-video-message`
                            );
                        } else {
                            setCurrentMessage(messageData);
                            setVideoId(messageData.data.media.mp4.secure_url);
                            setIsloading(false);
                            setBgColor(
                                getCategoryColor(
                                    messageData?.data?.categories[0].toLowerCase()
                                )
                            );
                        }
                    }
                })
                .catch((error) => console.log("error", error));
        };

        if (exists(window)) {
            if (search.id) {
                getSingleMessage(search.id);
            } else {
                navigate(`/${locale}/map`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contentTransition = {
        exit: {
            opacity: 0,
        },

        enter: {
            opacity: 1,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 1,
            },
        },
    };

    return (
        <>
            <motion.div
                initial="initial"
                exit="exit"
                animate
                className={messageClassName}
                style={{
                    backgroundColor: `${bgColor}`,
                }}
            >
                <CloseButton
                    onClick={() => closeMessage(false)}
                    componentClass={
                        bgColor === "#F4FF5F" ? "close-button--black" : ""
                    }
                />

                <div
                    className={
                        bgColor === "#F4FF5F"
                            ? "report-container report-container--black"
                            : "report-container"
                    }
                    onClick={() => setReportMessage(true)}
                >
                    <p>{messagePageData?.reportCtaText}</p>
                    <ReportIcon className="report-icon" />
                </div>

                <ReportMessage
                    messagePageData={messagePageData}
                    isReportMessage={isReportMessage}
                    setReportMessage={setReportMessage}
                    messageId={search.id}
                    locale={locale}
                    flags={flagsData}
                />
                {videoId && currentMessage ? (
                    <>
                        <Helmet>
                            <meta
                                property="og:image"
                                content={
                                    currentMessage?.data?.media.jpg.secure_url
                                }
                            />
                        </Helmet>
                        <div className="message__wrapper">
                            <motion.div
                                initial="exit"
                                exit="exit"
                                animate="enter"
                                variants={contentTransition}
                                className="message__video"
                                onClick={togglePlaystate}
                                onContextMenu={(e) => e.preventDefault()}
                            >
                                <OverlayShadow position="top" />
                                <div className="message__location">
                                    {currentMessage?.data?.categories?.map(
                                        (category, key) => {
                                            const localCategory =
                                                categories.filter(
                                                    (item) =>
                                                        item.node.slug ===
                                                        category
                                                );
                                            return (
                                                <h2 key={key}>
                                                    {
                                                        localCategory[0]?.node
                                                            ?.name
                                                    }
                                                    .
                                                </h2>
                                            );
                                        }
                                    )}
                                    <span>
                                        {currentMessage?.data?.geo?.locality}.{" "}
                                        {currentMessage?.data?.geo?.country}
                                    </span>
                                </div>
                                <div className="message__video-container">
                                    <ReactPlayer
                                        ref={videoRef}
                                        {...videoProps}
                                    />
                                </div>
                                <OverlayShadow position="bottom" />
                                <ProgressBar
                                    played={played}
                                    onSliderChange={onSliderChange}
                                    duration={duration}
                                    playing={playing}
                                    togglePlaystate={togglePlaystate}
                                    onBeforeChange={onBeforeChange}
                                    onAfterChange={onAfterChange}
                                />
                            </motion.div>
                            <motion.div
                                className={
                                    bgColor === "#F4FF5F"
                                        ? "actions actions--black"
                                        : "actions"
                                }
                                whileHover={{ y: -20 }}
                            >
                                <IconButton
                                    Icon={MapIcon}
                                    className="actions__map"
                                    onClick={() => closeMessage(true)}
                                    labelText={globalTexts.mapLabel}
                                />

                                <ShareButton
                                    postTitle="your post"
                                    siteTitle={`https://www.earthspeakr.art/${locale}`}
                                    locale={locale}
                                    globalTexts={globalTexts}
                                    shareUrl={currentMessage?.data?.share_url}
                                >
                                    <SocialIcon className="actions_social" />
                                    <p className="share-button__label">
                                        {globalTexts.shareLabel}
                                    </p>
                                </ShareButton>
                            </motion.div>
                        </div>
                    </>
                ) : isLoading ? (
                    <LoadingIcon />
                ) : null}
            </motion.div>
        </>
    );
};

export default withLocation(MessageTemplate);
