import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";

const Button = React.forwardRef(
    (
        { className, onClick, children, ariaLabel, pulse, disabled, type },
        ref
    ) => {
        return (
            <button
                className={`${className} button`}
                onClick={onClick}
                aria-label={ariaLabel}
                type={type || "button"}
                disabled={disabled}
                ref={ref}
            >
                {pulse && (
                    <>
                        <div className="pulse"></div>
                        <div className="pulse-2"></div>
                    </>
                )}

                {children}
            </button>
        );
    }
);

Button.defaultProps = {
    pulse: false,
};

Button.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    ariaLabel: PropTypes.string.isRequired,
    pulse: PropTypes.bool,
};

export default Button;
