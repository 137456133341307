import React from "react";
import SEO from "components/seo";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import MessageLayout from "components/layout/MessageLayout";
import config from "../../../config/site-config";
import FontPreload from "components/font-preload";

const MessagePage = ({ pageContext, data, location }) => {
    const { messageData, messagePageData, flagsData, globalTexts, categories } =
        data;
    const { locale } = pageContext;

    const seo = {
        lang: locale,
        title: globalTexts.shareMessageTitle,
        description: globalTexts.shareMessageText,
    };

    return (
        <>
            <SEO lang={locale} {...seo} />
            <Helmet>
                {data.languages.edges.map((currentSlug, key) => {
                    return (
                        <link
                            key={key}
                            rel="alternate"
                            href={`${config.siteUrl}/${currentSlug.node.code}`}
                            hreflang={
                                currentSlug.node.code === "en"
                                    ? "x-default"
                                    : currentSlug.node.code
                            }
                        />
                    );
                })}
            </Helmet>
            <FontPreload />
            <MessageLayout
                pageType="messagepage"
                messageData={messageData}
                messagePageData={messagePageData}
                flagsData={flagsData}
                globalTexts={globalTexts}
                locale={locale}
                location={location}
                categories={categories.edges}
            />
        </>
    );
};

export default MessagePage;

export const query = graphql`
    query messageData($locale: String!) {
        messageData: datoCmsFrontpage(locale: { eq: $locale }) {
            id: originalId
            aboutPageDescription
            appUrlAndroid
            appUrlIos
            appUrlLabel
            mainCtaLabel
            logoText
            seo {
                title
                description
                twitterCard
                image {
                    url
                }
            }
            takeActionLink {
                menuTitle
                slug
            }
            articlePageLink {
                menuTitle
                slug
            }
        }
        messagePageData: datoCmsMessagePage(locale: { eq: $locale }) {
            reportMessageTitle
            reportConfirmationText
            reportCloseText
            reportMessageText
            submitButtonText
            reportCtaText
        }
        categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id: originalId
                    name
                    locale
                    slug
                    color
                }
            }
        }
        languages: allDatoCmsLanguage(filter: { locale: { eq: "en" } }) {
            edges {
                node {
                    nativeName
                    name
                    code
                }
            }
        }
        flagsData: allDatoCmsFlag(
            filter: { locale: { eq: $locale } }
            sort: { fields: position }
        ) {
            edges {
                node {
                    name
                    slug
                    locale
                }
            }
        }
        globalTexts: datoCmsGlobalText(locale: { eq: $locale }) {
            copiedLinkLabel
            copyLinkLabel
            shareLabel
            mapLabel
            shareMessageTitle
            shareMessageText
            toolkitsMenuLabel
        }
    }
`;
